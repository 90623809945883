<template>
  <!-- 询价单 -->
  <div>
    <div class="type-select">
      <div class="item"
        :class="{ active: curType === '' }"
        @click="handleSelect('')">
        全部（{{statusStatistics.allCount || 0}}）
      </div>
      <div class="item"
        :class="{ active: curType === 0 }"
        @click="handleSelect(0)">
        未处理（{{statusStatistics.unprocessed || 0}}）
      </div>
      <div class="item"
        :class="{ active: curType === 1 }"
        @click="handleSelect(1)">
        已处理（{{statusStatistics.processed || 0}}）
      </div>
    </div>
    <GeneralTable ref="GeneralTable"
      :column="column"
      :option="option"
      :dataList="dataList"
      @search-change="handleSearch"
      @export-data="handleExport"
      @del-change="handleDel"
      @add-change="handleEdit('add')"
      @import-click="importClick"
      :totalNum="totalNum"
      tableName="enquiryManagement222">
      <template slot="operate-left">
        <el-button type="primary"
          size="mini"
          v-if="handleHasPerms('M26-allocationCustomer')"
          @click="handleDealWith()">批量处理</el-button>
      </template>
      <template slot="enquiryNo"
        slot-scope="{ item }">
        <div class="el-button polecy-button el-button--text el-button--small"
          style="text-decoration: underline; padding: 0"
          @click="handleEdit('details', item)">
          {{ item.enquiryNo }}
        </div>
      </template>
      <template slot="brandName"
        slot-scope="{ item }">
        {{ item.isSingle !== false ? item.brandName : '多标的类型，详细查看详情' }}
      </template>
      <template slot="frameNo"
        slot-scope="{ item }">
        {{ item.isSingle !== false ? item.frameNo : '多标的类型，详细查看详情' }}
      </template>
      <template slot="equipmentType"
        slot-scope="{ item }">
        {{ item.isSingle !== false ? item.equipmentType : '多标的类型，详细查看详情' }}
      </template>
      <template slot="dueTime"
        slot-scope="{ item }">{{ item.dueTime }}月</template>
      <template slot="dealStatus"
        slot-scope="{ item }">
        {{item.dealStatus==1?'已处理':'未处理'}}
      </template>
      <template slot="operate"
        slot-scope="{ item }">
        <el-button type="text"
          @click="handleApplyClaim(item)"
          v-if="handleHasPerms('M26-applyClaim') && (item.approvalStatus == 5 || item.approvalStatus == 3)">申请理赔</el-button>
        <el-button type="text"
          @click="handleDealWith([item])"
          v-if="handleHasPerms('M26-insurance') && item.dealStatus == 0">处理</el-button>
        <el-button type="text"
          @click="handleEdit('details', item)"
          v-if="handleHasPerms('M26-details')">查看详情</el-button>
        <el-button type="text"
          v-if="handleHasPerms('M26-edit')"
          @click="handleEdit('edit', item)">编辑</el-button>
        <el-button type="text"
          @click="handleDel([item])"
          v-if="handleHasPerms('M26-del')">删除</el-button>
      </template>
    </GeneralTable>
    <!-- <import-dialog v-model="isImport"
      @changeList="changeList" /> -->
    <hostingImport v-model="isImport"
      @changeList="
        getList()" />
    <turn-policy v-model="isTurnPolicy"
      :id="turnPolicyId" />
    <allocationCustomerDialog :ids="customerIds"
      v-model="isAllocationCustomer" />
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import { hasPerms, dataPermissions } from "@/utils/auth";
// import importDialog from "./components/importDialog.vue";
import allocationCustomerDialog from "@/views/policyManagement/components/allocationCustomerDialog";
import hostingImport from "@/views/policyManagement/components/hostingImport.vue";
import {
  enquiryList,
  delEnquiryList,
  changeToInsurance,
  insEnquiryArchiveFast,
  dictionaryBatch,
  generateQuotationSheet,
  policyList,
  trusteeshipPage,
  trusteeshipDel,
  trusteeshipExport,
  trusteeshipBatchProcess,
  trusteeshipStatusStatistics
} from "@/api/policy.js";
import TurnPolicy from "@/views/policyManagement/components/TurnPolicy.vue";
import dayjs from 'dayjs';
export default {
  name: 'policyHosting',
  components: {
    GeneralTable,
    //  importDialog,
    TurnPolicy,
    allocationCustomerDialog,
    hostingImport
  },
  data() {
    return {
      isImport: false,
      totalNum: 0,
      totalNum2: 0,
      dataList: [],
      dataList2: [],
      option: {
        isAdd: true, //添加 事件 @add-change
        isDel: true, //删除 @del-change
        isEdit: true, // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        isExport: true, // 刷新 @search-change
        isImport: true,
        pageSize: 10,
        searchList: [
          {
            label: "保单号",
            prop: "policyNo",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请选择",
          },
          {
            label: "起保时间",
            prop: ["startDateBegin", "startDateEnd"],
            formType: "daterange",
            format: "yyyy.MM.dd",
            clearable: true,
          },
          {
            label: "到保时间",
            prop: ["endDateBegin", "endDateEnd"],
            formType: "daterange",
            format: "yyyy.MM.dd",
            clearable: true,
          },
          {
            label: "所属客户",
            prop: "bindUserName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "联系电话",
            prop: "insuredPhone",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          //  {
          //    label: "托管单号",
          //    prop: "trusteeshipNo",
          //    formType: "input",
          //    maxLength: 36,
          //    clearable: true,
          //    placeholder: "请输入",
          //  },
          {
            label: "被保险人",
            prop: "insured",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "车牌号",
            prop: "plate",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "机身编号(车架号)",
            prop: "frameNo",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "业务员",
            prop: "salesmanName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "创建人",
            prop: "recorderName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          //  {
          //    label: "处理状态",
          //    prop: "dealStatus",
          //    formType: "select",
          //    filterable: true,
          //    disabled: false,
          //    rowPlaceholder: '',
          //    selectList: [
          //      { value: "", text: "全部" },
          //      { value: "1", text: "已处理" },
          //      { value: "0", text: "未处理" },
          //    ],
          //  },
          {
            label: "创建时间",
            // formType: "daterange",
            prop: ["createTimeBegin", "createTimeEnd"],
            formType: "daterange",
            clearable: true,
            format: "yyyy.MM.dd",
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        //   {
        //     label: "托管单号",
        //     prop: "trusteeshipNo",
        //     width: 280,
        //     isShow: true,
        //   },
        {
          label: "保单号",
          prop: "policyNo",
          width: 220,
          isShow: true,
        },
        {
          label: "起保时间",
          prop: "startDate",
          width: 120,
          isShow: true,
        },
        {
          label: "到保时间",
          prop: "endDate",
          width: 220,
          isShow: true,
        },
        {
          label: "总保费",
          prop: "totalPremium",
          width: 220,
          isShow: true,
        },
        {
          label: "总保额",
          prop: "totalAmount",
          width: 220,
          isShow: true,
        },
        {
          label: "被保险人",
          prop: "insured",
          isShow: true,
          width: 200,
        },
        {
          label: "联系电话",
          prop: "insuredPhone",
          width: 200,
          isShow: true,
        },
        {
          label: "车牌号",
          prop: "plate",
          width: 200,
          isShow: true,
        },
        {
          label: "机身编号(车架号)",
          prop: "frameNo",
          width: 265,
          isShow: true,
        },
        {
          label: "所属客户",
          prop: "bindUserName",
          width: 265,
          isShow: true,
        },
        {
          label: "业务员",
          prop: "salesmanName",
          isShow: true,
        },
        {
          label: "创建人",
          prop: "recorderName",
          isShow: true,
        },
        {
          label: "添加时间",
          prop: "createTime",
          isShow: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          isShow: true,
        },
        {
          label: "处理状态",
          prop: "dealStatus",
          isShow: true,
          isSlot: true,
        },
        {
          label: "处理时间",
          prop: "processTime",
          width: 200,
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 220,
          fixed: "right",
          isSlot: true,
        },
      ],
      curType: '',
      dataList1: [],
      isTurnPolicy: false,
      turnPolicyId: "",
      isFast: false,
      oldData: {},
      customerIds: [],
      isAllocationCustomer: false,
      statusStatistics: {},
      oldDataSearch: {},
    };
  },
  created() {
    this.initSearch();
  },
  computed: {},
  methods: {
    // 数量
    initSearch() {
      // 证件类型identCardType 保险公司（insuranceCompany）、设备品牌（vehicleBrand）、设备类型（vehicleType）主险种编码：primaryInsuranceType 附加险种编码：attachInsuranceType 投保门店applicantStore 投保方式applicantMode 投保类型applicantType
      trusteeshipStatusStatistics().then((res) => {
        if (res && res.data) {
          this.statusStatistics = res.data;
        }
      });
    },
    handleDel(data) {
      if (data && data.length) {
        this.$confirm("删除后数据不能恢复是否确认要删除？", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let temp = {
              ids: [],
            };

            data.forEach((element) => {
              temp.ids.push(element.id);
            });
            temp.ids = temp.ids.join(",");
            trusteeshipDel(temp).then((res) => {
              if (res) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                });
                this.$refs.GeneralTable.getList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.error("请选择需要删除的数据！");
      }
    },
    handleEdit(type, item, id) {
      if (type == "add") {
        this.$router.push({ name: "hostingEdit" });
      } else if (type == "details") {
        this.$router.push({
          path: "/routerPolicyManagement/hostingDetails?id=" + item.id,
        });
      } else {
        //edits
        this.$router.push({
          path:
            "/routerPolicyManagement/hostingEdit?id=" + item.id,
        });
      }
    },
    handleSearch(data, pageNum, pageSize, searcType, isReset) {
      data.dealStatus = data.dealStatus;
      data.current = data.pageNum;
      data.size = data.pageSize;
      data.likeParam = data.keyword;
      if (data.createTimeBegin) {
        data.createTimeBegin = dayjs(data.createTimeBegin).format('YYYY-MM-DD') + " 00:00:00";
        data.createTimeEnd = dayjs(data.createTimeEnd).format('YYYY-MM-DD') + " 23:59:59";
        //   data.createTimeEnd = dayjs(data.createTimeEnd).format('YYYY-MM-DD') + " 23:59:59";
      }
      // if (data.startDateBegin) {
      //   data.startDateBegin = dayjs(data.startDateBegin).format('YYYY-MM-DD') + " 00:00:00";
      //   data.startDateEND = dayjs(data.startDateEND).format('YYYY-MM-DD') + " 00:00:00";
      // }
      // if (data.endDateBegin) {
      //   data.endDateBegin = dayjs(data.endDateBegin).format('YYYY-MM-DD') + " 23:59:59";
      //   data.endDateEND = dayjs(data.endDateEND).format('YYYY-MM-DD') + " 23:59:59";
      // }
      // if (isReset == "refresh") {
      //   if (this.isFast) {startTime endTime createTimeStart", "createTimeEnd
      //     this.getList2(this.oldData);
      //   } else {
      //     this.getList(this.oldData);
      //   }
      // } else
      // if (isReset == "fuzzy") {
      //   this.isFast = true;
      //   this.oldData = JSON.parse(JSON.stringify(data));
      //   this.getList2(data);
      // } else {
      this.isFast = false;
      this.oldData = JSON.parse(JSON.stringify(data));
      this.getList(data, { current: data.pageNum, size: data.pageSize });
      // }
    },
    getList(data, params) {
      policyList({ ...data, orderType: 8, dealStatus: this.curType }, params).then((res) => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      }).catch(err => {
        this.dataList = [];
        this.totalNum = 0;
      });
    },
    handleSelect(type) {
      this.curType = type;
      let ind = this.option.searchList.findIndex(
        (val) => val.label === "处理状态"
      );
      // this.option.searchList[ind].disabled = this.curType != '';
      this.$refs.GeneralTable.searchData.pageNum = 1;
      this.$refs.GeneralTable.getList();
    },
    importClick() {
      this.isImport = true;
    },
    changeList() {
      this.$refs.GeneralTable.getList();
    },
    handleExport(data) {
      // if (!data || !data.length) {
      //   this.$message.warning("请选择需要导出的托管单！");
      //   return false;
      // }
      let ids = data.map((val) => val.id).toString();
      let dataObj = {};
      console.log(ids,'idsidsids');
      if (ids) {
        dataObj.ids = ids;
      } else {
        dataObj = JSON.parse(JSON.stringify(this.oldData));
        delete dataObj.current;
        delete dataObj.pageSize;
        delete dataObj.pageNum;
        delete dataObj.size;
      }
      console.log(dataObj,'idsidsids');
      trusteeshipExport(dataObj).then((res) => {
        if (res) {
          this.$message({
            message: "导出成功",
            type: "success",
          });
        }
      });
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
    handleChangeToInsurance(id) {
      changeToInsurance({ id });
    },
    handleAllocationCustomer() {
      this.customerIds = this.$refs.GeneralTable.tableSelectionList.map((val) => val.id);
      if (this.customerIds.length) {
        this.isAllocationCustomer = true;
      } else {
        this.$message.error('请选择询价单');
      }
    },
    handleDealWith(data) {
      if (!data) {
        data = this.$refs.GeneralTable.tableSelectionList;
      }
      data = data.filter(val => val.dealStatus == 0);
      if (data && data.length) {
        this.$confirm("是否完成了保单信息补录?", "处理", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            let temp = {
              ids: [],
            };

            data.forEach((element) => {
              temp.ids.push(element.id);
            });
            temp.ids = temp.ids.join(",");
            console.log(temp);
            trusteeshipBatchProcess(temp).then((res) => {
              if (res) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                });
                this.$refs.GeneralTable.getList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.error("请选择需要处理的有效数据！");
      }
    },
    handleApplyClaim(item) {
      this.$router.push({
          path: `/routerClaimManagement/applyClaim?policyId=${item.id}&category=${1}&orderType=${8}`,
        });
    }
  },
};
</script>
<style lang="scss" scoped>
.type-select {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: -10px;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
  .item {
    position: relative;
    padding: 13px 20px;
    color: #606266;
    margin-right: 20px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: #409eff;
    }
  }
  .active {
    color: #4278c9;
    font-weight: bold;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      border-radius: 4px;
      background-color: #4278c9;
    }
  }
}
</style>
